import React, { useState, useEffect, useRef, useCallback } from "react";
import styled from "styled-components";

import { useResource } from "hooks/useResource";
import useClickOutside from "hooks/useClickOutside";

import { AnalysisResultsSection } from "./AnalysisResultsSection";
import { CommentFeedbackForm } from "../FeedbackForm";
import { DemographicsSection } from "./DemographicsSection";

import { Icon, IconType } from "components/_icons/Icon";
import { Tooltip } from "components/Tooltip";
import { Text } from "components/Text";
import { Button } from "components/_buttons/Button";

import { Color } from "ts/enums/color";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";
import { Comment } from "ts/comments";
import { ButtonSize, ButtonVariant } from "ts/enums/button";

type Props = {
  comment: Comment;
};

export const CommentDetailsPopup = ({ comment }: Props) => {
  const [showCommentDetailsPopup, setShowCommentDetailsPopup] = useState<boolean>(false);
  const [showCommentDetailsTooltip, setShowCommentDetailsTooltip] = useState<boolean>(false);
  const [popupTop, setPopupTop] = useState<number>();

  const commentDetailsPopupRef = useClickOutside(() => setShowCommentDetailsPopup(false));
  const popupRef = useRef<HTMLDivElement>();
  const popupBodyRef = useRef<HTMLDivElement>();
  const { getResource } = useResource();

  const handleIconClick = () => setShowCommentDetailsPopup((show) => !show);

  const getDefaultPosition = useCallback(() => {
    if (commentDetailsPopupRef.current && popupRef.current && showCommentDetailsPopup) {
      const buttonTop = commentDetailsPopupRef.current.getBoundingClientRect().top;
      const popupHeight = popupRef.current.getBoundingClientRect().height;
      const halfHeight = popupHeight / 2;
      const popupPosition = buttonTop - halfHeight;

      setPopupTop(popupPosition);
    }
  }, [commentDetailsPopupRef, showCommentDetailsPopup]);

  useEffect(() => {
    if (showCommentDetailsPopup && popupBodyRef.current) popupBodyRef.current.scrollTop = 0;
  }, [showCommentDetailsPopup]);

  useEffect(() => {
    if (
      popupTop &&
      popupRef?.current?.getBoundingClientRect().bottom > window.innerHeight - 10 &&
      showCommentDetailsPopup
    )
      setPopupTop(window.innerHeight - popupRef.current.getBoundingClientRect().height - 10);
  }, [popupTop, showCommentDetailsPopup]);

  useEffect(() => {
    getDefaultPosition();
  }, [getDefaultPosition]);

  return (
    <StyledCommentDetailsContainer ref={commentDetailsPopupRef}>
      <div
        onMouseEnter={() => setShowCommentDetailsTooltip(true)}
        onMouseLeave={() => setShowCommentDetailsTooltip(false)}
        data-tooltip-id={"comment-details-button"}
      >
        <Button
          variant={ButtonVariant.light}
          square
          size={ButtonSize.sm}
          onClick={handleIconClick}
          style={{ color: Color.gray20 }}
        >
          <Icon type={IconType.searchFile} color={Color.gray20} size={12} />
        </Button>
        {showCommentDetailsTooltip && (
          <Tooltip
            tooltipId={"comment-details-button"}
            content={getResource("comments.detailsPopup.tooltip")}
            isOpen
          />
        )}
      </div>
      {showCommentDetailsPopup && !!comment && (
        <StyledCommentDetailsPopup top={popupTop} ref={popupRef}>
          <StyledPopupHeader>
            <StyledCommentDetailsTitle>
              <Text resource="comments.detailsPopup.title" />
            </StyledCommentDetailsTitle>
            <StyledCommentDetailsColumn>{comment.selectedColumn}</StyledCommentDetailsColumn>
            <StyledCommentDetailsBody>{comment.value}</StyledCommentDetailsBody>
          </StyledPopupHeader>
          <StyledPopupBody ref={popupBodyRef}>
            <AnalysisResultsSection
              results={{
                alertScore: comment.alertScore,
                hasAlert: comment.hasAlert,
                commentPolarity: comment.commentPolarity,
                categorizedTopics: comment.categorizedTopics,
                recommendations: comment.recommendations,
                categorizedRecommendations: comment.categorizedRecommendations,
              }}
            />
            <DemographicsSection sectionDetails={comment.demographics || {}} />
            <CommentFeedbackForm comment={comment} />
          </StyledPopupBody>
        </StyledCommentDetailsPopup>
      )}
    </StyledCommentDetailsContainer>
  );
};

const StyledCommentDetailsContainer = styled.div`
  position: relative;
`;

const StyledCommentDetailsPopup = styled.div<{
  top: number;
}>`
  position: fixed;
  top: ${({ top }) => top}px;
  width: 455px;
  max-height: 475px;
  background: ${Color.white} 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 2px;
  border: 1px solid ${Color.sky50};
  z-index: ${ZIndexStackingContext.high + 1};
  padding: 10px;
  opacity: ${({ top }) => (top ? "1" : "0")};
  transition: opacity ease 0.3s, visibility ease 0.3s;
  display: flex;
  flex-direction: column;
  transform: translateX(-100%);
`;

const StyledPopupHeader = styled.div`
  background-color: ${Color.sky15};
  padding: 12px;
  max-width: 450px;
  display: flex;
  flex-direction: column;
`;

const StyledCommentDetailsBody = styled.div`
  font-size: 14px;
  color: ${Color.gray50};
  max-height: 100px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledCommentDetailsColumn = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
  color: ${Color.gray50};
`;

const StyledCommentDetailsTitle = styled.div`
  font-size: 0.75em;
  font-weight: bold;
  margin-bottom: 10px;
  color: ${Color.gray30};
`;

const StyledPopupBody = styled.div`
  margin: 8px 0 0px 2px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    border: none;
    background: ${Color.sky50} 0% 0% no-repeat padding-box;
  }

  ::-webkit-scrollbar-track {
    background: ${Color.sky20} 0% 0% no-repeat padding-box;
    border-radius: 6px;
  }
`;
