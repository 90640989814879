import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";

import { Text } from "components/Text";

import { Color } from "ts/enums/color";

type Props = {
  labelKey: string;
  isPending?: boolean;
  isDisabled?: boolean;
  checked?: boolean;
  showLabelToRight?: boolean;
  onChange: (...args) => void;
};

export const ToggleSwitch = ({
  labelKey,
  isPending,
  isDisabled,
  checked,
  showLabelToRight,
  onChange,
}: Props) => {
  const defaultChecked = checked ?? false;
  const [isChecked, setIsChecked] = useState<boolean>(defaultChecked);

  const handleChange = (checkedValue: boolean) => onChange(checkedValue);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <StyledToggleSwitchContainer
      isDisabled={isDisabled}
      isPending={isPending}
      onClick={isPending || isDisabled ? undefined : () => handleChange(!isChecked)}
    >
      {!showLabelToRight && (
        <StyledLabel showLabelToRight={showLabelToRight} htmlFor="toggle-checkbox">
          <Text resource={labelKey} />
        </StyledLabel>
      )}
      <StyledInputContainer>
        <StyledSwitch checked={isChecked} isDisabled={isDisabled}>
          <StyledBall checked={isChecked} />
        </StyledSwitch>
      </StyledInputContainer>
      {showLabelToRight && (
        <StyledLabel showLabelToRight={showLabelToRight} htmlFor="toggle-checkbox">
          <Text resource={labelKey} />
        </StyledLabel>
      )}
    </StyledToggleSwitchContainer>
  );
};

const StyledToggleSwitchContainer = styled.div<{ isDisabled: boolean; isPending: boolean }>`
  display: flex;
  align-items: center;

  ${({ isDisabled, isPending }) =>
    isDisabled
      ? css`
          cursor: default;
        `
      : isPending
      ? css`
          transition: opacity 0.15s ease-in;
          opacity: 0.75;
          cursor: default;
        `
      : css`
          cursor: pointer;
          * {
            cursor: pointer;
          }
        `};
`;

const StyledLabel = styled.label<{ showLabelToRight: boolean }>`
  font-weight: bold;
  font-size: 14px;
  color: ${Color.gray50};
  margin: ${({ showLabelToRight }) => (showLabelToRight ? "0px 0px 0px 7px" : "0px 7px 0px 0px")};
  user-select: none;
  font-family: Lato;
`;

const StyledInputContainer = styled.div`
  position: relative;
  min-width: 36px;
  height: 20px;
`;

const StyledSwitch = styled.div<{ checked: boolean; isDisabled: boolean }>`
  min-width: 100%;
  height: 100%;
  transition: 0.3s;
  background-color: ${({ checked, isDisabled }) =>
    checked && !isDisabled ? Color.blue50 : Color.blue20};
  border-radius: 100px;
  display: flex;
  align-items: center;
  padding: 0 2px;
`;

const StyledBall = styled.div<{ checked: boolean }>`
  width: 16px;
  height: 16px;
  transition: 0.3s;
  margin-left: ${({ checked }) => (checked ? 16 : 0)}px;
  background-color: ${Color.white};
  border-radius: 100px;
`;
