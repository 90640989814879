import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { DndContext, DragOverlay, rectIntersection, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { useAppSelector, useAppDispatch } from "store";
import { AnalysisContext } from "context/AnalysisContext";

import { SmartMouseSensor } from "./helpers";
import { showToastSuccess } from "store/toast/toastSlice";
import { saveWidgetOrdering } from "store/widgetOrdering/thunks";
import { openModal } from "store/widgets/widgetGroupsSlice";

import { Modal } from "components/_modals/Modal";
import { Button } from "components/_buttons/Button";
import { CloseButton } from "components/_buttons/CloseButton";
import { DraggableGroupCard } from "./DraggableGroupCard";
import { DraggableWidgetCard } from "./DraggableWidgetCard";
import { Icon, IconType } from "components/_icons/Icon";
import { Text } from "components/Text";
import {
  handleDragEnd,
  handleDragStart,
  onClose,
  setGroups,
} from "store/widgetOrdering/widgetOrderingModal";

import { Color } from "ts/enums/color";
import { WidgetGroupModalType } from "ts/enums/widgetGroupModalType";
import { ButtonSize, ButtonVariant } from "ts/enums/button";

type Props = {
  refetch: () => void;
};

export const ManageWidgetsModal = ({ refetch }: Props) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.widgetOrdering);

  const [analysisState] = useContext(AnalysisContext);

  const sensors = useSensors(useSensor(SmartMouseSensor));

  const handleCreateNewGroup = () => {
    dispatch(onClose());
    dispatch(openModal(WidgetGroupModalType.newGroup));
  };

  const handleSaveWidgetOrdering = async () => {
    await dispatch(saveWidgetOrdering(analysisState.analysisDetails.id));
    dispatch(showToastSuccess("widget.toast.success.reorderedWidgets"));
    refetch();
  };

  useEffect(() => {
    if (!analysisState.widgetsApiData?.widgetGroups) return;
    if (state.showManageWidgetsModal) {
      dispatch(setGroups(analysisState.widgetsApiData?.widgetGroups));
    }
  }, [state.showManageWidgetsModal]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      isOpen={state.showManageWidgetsModal}
      width="700px"
      height="600px"
      padding="18px 24px"
      margin="1% auto"
    >
      <StyledModalContent>
        <StyledUpperSection>
          <StyledHeaderSection>
            <StyledHeader>
              <Text resource="modal.manageWidgets.title" />
              <CloseButton titleKey="button.close" closeHandler={() => dispatch(onClose())} />
            </StyledHeader>
            <Text resource="modal.manageWidgets.description" />
          </StyledHeaderSection>
          {state.groups?.length === 0 && state.showManageWidgetsModal ? (
            <StyledNoSectionsEmptyState>
              <Text resource="modal.manageWidgets.noSections" />
              <Text resource="modal.manageWidgets.noSections.description" />
              <Button
                size={ButtonSize.md}
                variant={ButtonVariant.secondary}
                onClick={handleCreateNewGroup}
              >
                <StyledAddSectionButtonContent>
                  <Icon type={IconType.plusCircle} size={14} color={Color.blue50} />
                  <Text resource="modal.manageWidgets.new.group" />
                </StyledAddSectionButtonContent>
              </Button>
            </StyledNoSectionsEmptyState>
          ) : (
            <StyledDndSection>
              <DndContext
                sensors={sensors}
                onDragEnd={({ active, over }) =>
                  dispatch(handleDragEnd({ activeId: active?.id, overId: over?.id }))
                }
                onDragStart={(e) => dispatch(handleDragStart(e))}
                collisionDetection={rectIntersection}
              >
                <SortableContext items={state.groups} strategy={verticalListSortingStrategy}>
                  {state.groups.map((group) => (
                    <DraggableGroupCard
                      key={group.id}
                      title={group.title}
                      id={group.id}
                      widgets={group.widgets}
                    />
                  ))}
                </SortableContext>
                <DragOverlay adjustScale={false}>
                  {state.currentlyDragging && !state.currentlyDragging?.widgets ? (
                    <DraggableWidgetCard
                      widget={
                        {
                          ...state.currentlyDragging,
                          type: state.currentlyDragging?.widgetType,
                        } as any
                      }
                      dragging
                      sectionId={null}
                    />
                  ) : state.currentlyDragging ? (
                    <DraggableGroupCard
                      title={state.currentlyDragging.title}
                      id={state.currentlyDragging.id}
                      widgets={state.currentlyDragging.widgets}
                      isExpanded={state.currentlyDragging.isGroupInModalExpanded}
                    />
                  ) : null}
                </DragOverlay>
              </DndContext>
            </StyledDndSection>
          )}
        </StyledUpperSection>

        <StyledModalFooter>
          <Button
            onClick={() => dispatch(onClose())}
            size={ButtonSize.md}
            variant={ButtonVariant.light}
          >
            <Text resource="button.cancel" />
          </Button>
          <Button
            size={ButtonSize.md}
            onClick={() => handleSaveWidgetOrdering()}
            style={{ marginLeft: 12 }}
          >
            <Text resource="button.saveChanges" />
          </Button>
        </StyledModalFooter>
      </StyledModalContent>
    </Modal>
  );
};

const StyledHeader = styled.div`
  font-size: 20px;
  font-weight: bold;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const StyledModalFooter = styled.div`
  display: flex;
  gap: 6px;
  flex: none;
  justify-content: flex-end;
  padding-top: 18px;
`;

const StyledHeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 12px;
`;

const StyledUpperSection = styled.div`
  flex: 1;
  overflow: hidden;
`;

const StyledDndSection = styled.div`
  overflow-y: auto;
  height: 85%;
  padding: 5px;
  margin-left: -5px;
`;

const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledNoSectionsEmptyState = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 80%;
  h4 {
    font-size: 20px;
    margin: 0;
    color: ${Color.black};
  }
  p {
    font-size: 14px;
    color: ${Color.gray40};
    margin: 8px 0px 16px 0px;
  }
`;

const StyledAddSectionButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 7px;
`;
