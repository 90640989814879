import React, { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "store";
import styled from "styled-components";
import { cloneDeep } from "lodash";
import { useParams } from "react-router-dom";

import { useResource } from "hooks/useResource";
import { setSearchTerm } from "store/search/searchbarSlice";
import {
  fetchShareableUsers,
  refetchSharingUsersData,
  updateSelectedUsers,
  updateUsersInNewSharingDraft,
} from "store/sharing/thunks";
import {
  setCurrentPage,
  setIsUserDemographicFiltersReset,
  setSelectedUserDemographicFilters,
  setSelectedUsers,
  setSelectedUsersCount,
  setShareableUsersApiData,
  setSortOrder,
  setSortingColumn,
} from "store/sharing/userSelectionSlice";
import { getSharingMethodFromView } from "utils/getSharingMethodFromSharingView";
import { validateUsersShown } from "utils/sharing";

import { Header } from "./Header";
import { FirstNameCell } from "./FlexTableCells/FirstNameCell";
import { EmailCell } from "./FlexTableCells/EmailCell";
import { LastNameCell } from "./FlexTableCells/LastNameCell";
import { EmptyState } from "components/EmptyState";
import { FlexTable, FlexTableSorting } from "components/FlexTable";
import { Button } from "components/_buttons/Button";
import { Icon, IconType } from "components/_icons/Icon";
import { Text } from "components/Text";

import { FlexTableType } from "ts/enums/flexTable";
import { ShareStatus, ShareableUsersSortingParameter, SortingOrder } from "@explorance/mly-types";
import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Color } from "ts/enums/color";
import { EmptyStateType } from "ts/enums/emptyStateType";
import { TextTruncator } from "components/TextTruncator";

const SHARE_TO_USERS_HEADERS = [
  { columnNameKey: "firstName", sortingParameter: ShareableUsersSortingParameter.FirstName },
  { columnNameKey: "lastName", sortingParameter: ShareableUsersSortingParameter.LastName },
  { columnNameKey: "email", sortingParameter: ShareableUsersSortingParameter.Email },
  { columnNameKey: "role", sortingParameter: ShareableUsersSortingParameter.RoleType },
];

export const UserSelection = () => {
  const dispatch = useAppDispatch();
  const sharing = useAppSelector((state) => state.sharing);
  const userSelectionState = useAppSelector((state) => state.sharingUserSelection);

  const { getResource } = useResource();
  const analysisId = parseInt(useParams<{ analysisId: string }>().analysisId);

  const toggleSelectUser = (selectedUserId: number) => {
    //Make shareableUsersApiData reflect isSelected change
    const apiData = cloneDeep(userSelectionState.shareableUsersApiData);
    const apiUserIndex = apiData.users.findIndex((c) => c.id === selectedUserId);
    const isApiUserSelected = apiData.users[apiUserIndex].isSelected;

    if (apiUserIndex !== -1) {
      const apiUserIndex = apiData.users.findIndex((c) => c.id === selectedUserId);
      apiData.users.splice(apiUserIndex, 1, {
        ...apiData.users[apiUserIndex],
        isSelected: !isApiUserSelected,
      });
    }
    dispatch(setShareableUsersApiData(apiData));

    const copy = cloneDeep(userSelectionState.selectedUsers);
    const selectedUserIndex = copy.findIndex((c) => c.id === selectedUserId);
    if (isApiUserSelected) {
      if (selectedUserIndex !== -1) {
        copy.splice(selectedUserIndex, 1);
      }
    } else {
      copy.push(
        userSelectionState.shareableUsersApiData.users.find((u) => u.id === selectedUserId)
      );
    }
    dispatch(setSelectedUsers(validateUsersShown(userSelectionState.selectedUsers, copy)));
    dispatch(
      setSelectedUsersCount(userSelectionState.selectedUsersCount + (isApiUserSelected ? -1 : 1))
    );
    dispatch(
      updateUsersInNewSharingDraft({
        analysisId,
        isSelected: isApiUserSelected,
        sharingMethod: getSharingMethodFromView(sharing.view),
        userIds: [selectedUserId],
      })
    );
  };

  const toggleSelectAllUsers = () => {
    const allSelected = userSelectionState.shareableUsersApiData.users.every((u) => u.isSelected);
    const apiData = cloneDeep(userSelectionState.shareableUsersApiData);
    const selectedUsers = cloneDeep(userSelectionState.selectedUsers);
    apiData.users.forEach((u) => {
      u.isSelected = !allSelected;
    });
    dispatch(setShareableUsersApiData(apiData));
    if (allSelected) {
      const updatedSelectedUsers = selectedUsers.filter(
        (user) => !apiData.users.some((u) => u.id === user.id)
      );
      dispatch(
        setSelectedUsers(validateUsersShown(userSelectionState.selectedUsers, updatedSelectedUsers))
      );
    } else {
      const nonSelectedApiDataUsers = apiData.users.filter(
        (u) => !selectedUsers.some((su) => su.id === u.id)
      );
      const updatedSelectedUsers = [...selectedUsers, ...nonSelectedApiDataUsers];
      dispatch(
        setSelectedUsers(validateUsersShown(userSelectionState.selectedUsers, updatedSelectedUsers))
      );
    }
    const uniqueUserIds = userSelectionState.shareableUsersApiData.users.reduce((acc, u) => {
      if (u.isSelected === allSelected) {
        acc.push(u.id);
      }
      return acc;
    }, []);
    dispatch(
      setSelectedUsersCount(
        allSelected
          ? userSelectionState.selectedUsersCount - uniqueUserIds.length
          : userSelectionState.selectedUsersCount + uniqueUserIds.length
      )
    );
    dispatch(
      updateUsersInNewSharingDraft({
        analysisId,
        isSelected: allSelected,
        sharingMethod: getSharingMethodFromView(sharing.view),
        userIds: uniqueUserIds,
      })
    );
  };

  const updateSorting = (updatedSorting: FlexTableSorting) => {
    const { columnIndex, order } = updatedSorting;
    dispatch(setSortingColumn(SHARE_TO_USERS_HEADERS[columnIndex].sortingParameter));
    dispatch(setSortOrder(order));
    dispatch(setCurrentPage(1));
    dispatch(refetchSharingUsersData({ analysisId }));
  };

  const updatePage = (updatedPage: number) => {
    dispatch(setCurrentPage(updatedPage));
    dispatch(refetchSharingUsersData({ analysisId }));
  };

  const clearAllFilters = () => {
    dispatch(setSortingColumn(ShareableUsersSortingParameter.FirstName));
    dispatch(setSortOrder(SortingOrder.ASC));
    dispatch(setSearchTerm(""));
    dispatch(setIsUserDemographicFiltersReset(true));
    dispatch(setSelectedUserDemographicFilters([]));
    setTimeout(() => {
      dispatch(setIsUserDemographicFiltersReset(false));
    }, 500);
    dispatch(refetchSharingUsersData({ analysisId, demographicFilters: [] }));
  };

  useEffect(() => {
    dispatch(fetchShareableUsers(analysisId));
    dispatch(updateSelectedUsers(analysisId));
    return () => {
      clearAllFilters();
    };
  }, []); // eslint-disable-line

  return (
    <div className="fade-in">
      <Header
        pageTitle={getResource("userList.title")}
        searchCountDataType="searchBar.dataType.userList"
        currentPage={userSelectionState.currentPage}
        showUserDemographicFilters={true}
        isUserDemographicFiltersReset={userSelectionState.isUserDemographicFiltersReset}
        updatePage={updatePage}
        refetchList={(demographicFilters) =>
          dispatch(refetchSharingUsersData({ analysisId, demographicFilters }))
        }
        onSearch={() => dispatch(refetchSharingUsersData({ analysisId }))}
      />
      {userSelectionState.shareableUsersApiData.users.length === 0 ? (
        <EmptyState
          type={EmptyStateType.noSearchResults}
          customStyles={{ marginTop: "85px", marginBottom: "85px" }}
          handleClickCaptionLink={clearAllFilters}
        />
      ) : (
        <FlexTable
          type={FlexTableType.Card}
          data={{
            headers: SHARE_TO_USERS_HEADERS.map((header) =>
              getResource(`sharing.table.${header.columnNameKey}`)
            ),
            rows: userSelectionState.shareableUsersApiData.users.map((user) => ({
              isSelected: user.isSelected,
              contentId: user.id,
              data: [
                <FirstNameCell key={`${user.id}-firstname}`} user={user} />,
                <LastNameCell key={`${user.id}-lastname}`} user={user} />,
                <EmailCell key={`${user.id}-email}`} user={user} />,
                <TextTruncator
                  key={`${user.id}-role`}
                  value={<Text resource={`roleType.${user.roleType}`} />}
                />,
                <StyledSettingsCell key={user.id}>
                  {user.shareStatus === ShareStatus.Shared && user.isSelected ? (
                    <StyledOverrideSettingsPill>
                      <Text resource="sharing.pill.overrideSettings" />
                    </StyledOverrideSettingsPill>
                  ) : user.shareStatus === ShareStatus.Shared ? (
                    <StyledAlreadySharedPill>
                      <Text resource="sharing.pill.alreadyShared" />
                    </StyledAlreadySharedPill>
                  ) : (
                    <div />
                  )}
                  <Button
                    variant={ButtonVariant.secondary}
                    size={ButtonSize.sm}
                    style={{ justifySelf: "right" }}
                    onClick={() => toggleSelectUser(user.id)}
                  >
                    <Icon
                      type={user.isSelected ? IconType.xCircle : IconType.plusCircle}
                      size={14}
                      style={{ marginRight: 5 }}
                    />
                    <Text
                      resource={{
                        key: user.isSelected ? "button.deselectUser" : "button.selectUser",
                      }}
                    />
                  </Button>
                </StyledSettingsCell>,
              ],
            })),
            columnWidths: ["17.5%", "17.5%", "20%", "15%", "30%"],
          }}
          isSelectable
          maxHeight="400px"
          initialSorting={{
            columnIndex: SHARE_TO_USERS_HEADERS.findIndex(
              (h) => h.sortingParameter === userSelectionState.sortingColumn
            ),
            order: userSelectionState.sortOrder,
          }}
          onSelectionChange={toggleSelectUser}
          handleSelectAll={toggleSelectAllUsers}
          onSortingChange={updateSorting}
        />
      )}
    </div>
  );
};

const StyledAlreadySharedPill = styled.div`
  display: inline;
  padding: 6px 12px;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background-color: ${Color.indigo20};
  font-size: 14px;
  text-align: center;
  justify-self: center;
`;

const StyledSettingsCell = styled.div`
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  align-items: center;
`;

const StyledOverrideSettingsPill = styled.div`
  display: inline;
  padding: 6px 12px;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background-color: ${Color.indigo45};
  color: ${Color.white};
  font-size: 14px;
  text-align: center;
  justify-self: center;
`;
