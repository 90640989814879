import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import parseHTML from "html-react-parser";
import { useAppSelector } from "store";

import { routes } from "routes";
import { AnalysisContext } from "context/AnalysisContext";
import { useQueryParams } from "hooks/useQueryParams";
import { setSelectedTopicNodes, setLastSelectedTopicNodes } from "context/AnalysisContext/actions";
import {
  recursiveFindAllChildTopicNodes,
  recursiveFindAllParents,
  getAttributeCountsByView,
} from "utils/topics";
import { formatTopicsSentimentPercent } from "utils/formatters";
import { useResource } from "hooks/useResource";
import { isModelVirtual } from "utils/isModelVirtual";
import { getUrlQueryString } from "utils/getUrlQueryString";
import { isAnyAdmin } from "utils/isAnyAdmin";
import { getCustomModelId } from "utils/getCustomModelId";
import { formatDemographicsForApi } from "utils/filters";

import { isChildTopic } from "common-layouts/TopicFilterSelection/helpers";
import { TopicsTableRowContainer } from "./TopicsTableRowContainer";
import { ExpandCollapseButton } from "components/_buttons/ExpandCollapseButton";
import { SentimentIcon } from "components/_icons/SentimentIcon";
import { Tooltip } from "components/Tooltip";
import { RelevantCommentsModal } from "components/_modals/RelevantCommentsModal";
import { TopicSummaryButton } from "components/_buttons/TopicSummaryButton";

import { ApiTopicNode, Topic } from "ts/topic";
import { Color } from "ts/enums/color";
import { SentimentType, SharingCommentExplorerAccess, TopicsListView } from "@explorance/mly-types";

type Props = {
  depth: number;
  topic: Topic;
  selectedTopicPath: Array<string>;
  isImmediateParentExpanded: boolean;
  isAncestorExpanded: boolean;
  allTopics: Topic[];
  view: TopicsListView;
  handleTopicPathSelection(topicPath: Array<string>): void;
};

export const TopicsTableRow = ({
  depth,
  topic,
  selectedTopicPath,
  isImmediateParentExpanded,
  isAncestorExpanded,
  allTopics,
  view,
  handleTopicPathSelection,
}: Props) => {
  const { currentUser } = useAppSelector((state) => state.auth);

  const [state, dispatch] = useContext(AnalysisContext);
  const [showChildTopics, setShowChildTopics] = useState<boolean>(false);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const analysisId = parseInt(useParams<{ analysisId: string }>().analysisId);

  const { getResource } = useResource();
  const queryParams = new URLSearchParams(location.search);

  const history = useHistory();
  const { expandTree, sharingPreview, sharedWithPage, sharingId, expandedRowId, step } =
    useQueryParams();

  const handleOpenModal = () => setModalOpen(true);
  const handleRequestCloseFunc = () => setModalOpen(false);

  // variables
  const splitTopicPath = topic.fullPath.split(">");
  const childTopics = recursiveFindAllChildTopicNodes(topic);
  const thirdColumnCount =
    view === TopicsListView.Sentiments
      ? { count: topic.insights, type: "insights" }
      : { count: topic.recommendations, type: "recommendations" };
  const attributeCounts = getAttributeCountsByView(view, topic);
  const isTotalCountsRow = topic.fullPath === getResource("topics.totalRow");
  const topicModalData = { ...topic, dominantSentiment: topic.dominantSentiment?.type };
  const topicFilters = {
    demographics: formatDemographicsForApi(state.selectedDemographicFilters),
    threshold: state.threshold,
    ...(state?.selectedColumnFilters.length > 0 && {
      selectedColumns: state.selectedColumnFilters,
    }),
  };

  // functions
  const getRowColor = (): string => {
    if (!selectedTopicPath) return Color.white;

    let color;
    const currentLevel = selectedTopicPath[selectedTopicPath?.length - 1];

    currentLevel === topic.name ||
    (isChildTopic(topic) && currentLevel === splitTopicPath[selectedTopicPath?.length - 1])
      ? (color = Color.sky15)
      : (color = Color.white);

    return color;
  };

  const isChildTopicFilterActive = (): ApiTopicNode[] => {
    const allParentsArray = [];
    state.selectedTopicNodes.forEach((n) =>
      recursiveFindAllParents(n, allTopics).forEach((x) => allParentsArray.push(x))
    );
    const finalArray = allParentsArray.filter((p, index) => {
      return allParentsArray.map((x) => JSON.stringify(x)).lastIndexOf(JSON.stringify(p)) === index;
    });
    return finalArray.filter(Boolean).filter((p) => p.fullPath === topic.fullPath);
  };

  // event handlers
  const handleTopicNameHover = (e: React.MouseEvent<HTMLDivElement>) => {
    setShowTooltip(e.currentTarget.scrollWidth > e.currentTarget.offsetWidth);
  };

  const handleRowExpandCollapse = () => {
    setTimeout(() => {
      setShowChildTopics(!showChildTopics);
      handleTopicPathSelection(splitTopicPath);
    }, 250);
  };

  const handleDrilldown = (type: string) => {
    if (!userCanAccessComments) return;

    dispatch(setLastSelectedTopicNodes(state.selectedTopicNodes));

    // If the row is not the total row, add the appropriate topic filters
    if (!isTotalCountsRow) getTopicFiltersForCommentsPage();

    const queryParams: string = getNavigationToCommentsPageQueryParams(type);
    history.push(routes.commentsPage(analysisId, queryParams));
  };

  const getNavigationToCommentsPageQueryParams = (type: string): string => {
    const viewType =
      view === TopicsListView.Sentiments ? "categorizedComments" : "categorizedRecommendations";
    switch (type) {
      case "name":
      case "comments":
      case "insights":
      case "recommendations":
        return getUrlQueryString({
          view: viewType,
          sharingPreview,
          sharedWithPage,
          sharingId,
          expandedRowId,
          step,
        });
      default:
        return getUrlQueryString({
          view: viewType,
          statistics: type,
          sharingPreview,
          sharedWithPage,
          sharingId,
          expandedRowId,
          step,
        });
    }
  };

  const getTopicFiltersForCommentsPage = () => {
    const parentArray = isChildTopicFilterActive();
    // the parent topic is not an active filter but a child topic is
    if (parentArray.length > 0) {
      const newFilterArray: ApiTopicNode[] = [];
      state.selectedTopicNodes.forEach((stn) =>
        parentArray.forEach((p) => {
          if (stn.fullPath.includes(p.fullPath)) newFilterArray.push(stn);
        })
      );
      dispatch(setSelectedTopicNodes(newFilterArray));
      return;
    }
    // the parent topic is an active filter
    dispatch(setSelectedTopicNodes(childTopics));
  };

  const getTopicName = () =>
    isTotalCountsRow
      ? getResource("topics.totalRow")
      : isModelVirtual(state.selectedModelId, state.analysisModels)
      ? topic.name
      : getResource(`ML.topic.[${topic.name}]`);
  const userCanAccessComments = sharingPreview
    ? state.previewUser?.commentExplorerAccess === SharingCommentExplorerAccess.Shared
    : isAnyAdmin(currentUser.roleType) ||
      !state.analysisDetails?.sharing ||
      state.analysisDetails?.sharing?.commentExplorerAccess === SharingCommentExplorerAccess.Shared;

  useEffect(() => {
    if (expandTree) {
      setShowChildTopics(Boolean(expandTree));
      if (expandTree && state.selectedTopicNodes[0].fullPath === topic.fullPath) {
        handleTopicPathSelection(splitTopicPath);
      }
      queryParams.delete("expandTree");
      history.replace({ search: queryParams.toString() });
    }
  }, []); //eslint-disable-line

  const getTopicPathFromFullName = (fullPath: string, isTooltip?: boolean) => {
    if (!fullPath.includes(">")) return;
    const lastIndex = fullPath.lastIndexOf(">");
    const topicPath = fullPath
      .slice(0, lastIndex)
      .split(">")
      .map((topic) =>
        isModelVirtual(state.selectedModelId, state.analysisModels)
          ? topic
          : getResource(`ML.topic.[${topic}]`)
      );

    // The conversion of the arrow to string and use of dangerouslySetInnerHTML
    // is necessary to ensure that the ellipsis is applied to the parent topic name
    const iconToString = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 4" width="6px" height="6px" style="transform: rotate(-90deg); margin: 2px 4px"> <path id="Subtraction_68" data-name="Subtraction 68" d="M4,4,4,4,0,0H8L4,4Z" transform="translate(0)" fill={${Color.gray70}} /> </svg>`;

    return isTooltip ? (
      <StyledTopicParentNameTooltip>
        {parseHTML(topicPath.join(iconToString))}
      </StyledTopicParentNameTooltip>
    ) : (
      <StyledTopicParentName
        onMouseOver={handleTopicNameHover}
        onMouseOut={() => setShowTooltip(false)}
      >
        {parseHTML(topicPath.join(iconToString))}
      </StyledTopicParentName>
    );
  };

  return (
    <>
      <StyledTopicsTableRow
        className={isImmediateParentExpanded && isAncestorExpanded ? "visible" : "hidden"}
        depth={depth}
        rowColor={getRowColor()}
        hasNoChildren={topic.topics?.length === 0}
        hasParent={topic.parentId !== null}
        addDefaultCursor={isTotalCountsRow || !userCanAccessComments}
      >
        <td
          onClick={topic.topics?.length > 0 ? handleRowExpandCollapse : undefined}
          onDoubleClick={modalOpen || isTotalCountsRow ? undefined : () => handleDrilldown("name")}
        >
          <StyledCellNameContainer
            depth={depth}
            hasNoChildren={topic.topics?.length === 0}
            hasParent={topic.parentId !== null}
          >
            <div>
              {topic.topics?.length > 0 && (
                <ExpandCollapseButton
                  isCollapsed={!showChildTopics}
                  onClick={handleRowExpandCollapse}
                />
              )}
              {topic.topics?.length === 0 && !isTotalCountsRow && (
                <TopicSummaryButton topic={topicModalData} onClick={handleOpenModal} />
              )}
              <RelevantCommentsModal
                isOpen={modalOpen}
                onClose={handleRequestCloseFunc}
                topic={topicModalData}
                selectedView={view}
                filters={topicFilters}
                isVirtual={!!getCustomModelId(state)}
                showToggle
                showSentimentFeatures
              />
            </div>
            <StyledTopicNameContainer>
              {depth > 1 && topic.parentId && (
                <>
                  <StyledTopicParentNameContainer data-tooltip-id={`${topic.id}-${topic.fullPath}`}>
                    {getTopicPathFromFullName(topic.fullPath)}
                  </StyledTopicParentNameContainer>
                  {showTooltip && (
                    <Tooltip tooltipId={`${topic.id}-${topic.fullPath}`} isOpen>
                      {getTopicPathFromFullName(topic.fullPath, true)}
                    </Tooltip>
                  )}
                </>
              )}

              <StyledTopicName
                onMouseOver={handleTopicNameHover}
                onMouseOut={() => setShowTooltip(false)}
                data-tooltip-id={`${topic.id}-${topic.name}`}
              >
                {getTopicName()}
              </StyledTopicName>
              {showTooltip && (
                <Tooltip tooltipId={`${topic.id}-${topic.name}`} content={getTopicName()} isOpen />
              )}
            </StyledTopicNameContainer>
          </StyledCellNameContainer>
        </td>
        <td>
          <StyledCountCell
            isGreaterThanZero={topic.comments > 0}
            cursorStyle={topic.comments > 0 && userCanAccessComments ? "pointer" : "default"}
            onClick={topic.comments > 0 ? () => handleDrilldown("comments") : undefined}
          >
            {topic.comments}
          </StyledCountCell>
        </td>
        <td>
          <StyledCountCell
            isGreaterThanZero={thirdColumnCount.count > 0}
            cursorStyle={
              thirdColumnCount.count > 0 && userCanAccessComments ? "pointer" : "default"
            }
            onClick={
              thirdColumnCount.count > 0 ? () => handleDrilldown(thirdColumnCount.type) : undefined
            }
          >
            {thirdColumnCount.count}
          </StyledCountCell>
        </td>
        {attributeCounts.map((c) => (
          <td
            key={`${topic.fullPath}-${c.attribute}-${c.count}-isDominantSentiment=${c.isDominantSentiment}`}
          >
            {c.isDominantSentiment ? (
              <StyledSentimentCell
                cursorStyle={c.count > 0 && userCanAccessComments ? "pointer" : "default"}
                onClick={c.count > 0 ? () => handleDrilldown(c.attribute) : undefined}
              >
                <SentimentIcon type={c.attribute as SentimentType} size={16} />
                <div>{formatTopicsSentimentPercent(c.count)}</div>
              </StyledSentimentCell>
            ) : (
              <StyledCountCell
                isGreaterThanZero={c.count > 0}
                cursorStyle={c.count > 0 && userCanAccessComments ? "pointer" : "default"}
                onClick={c.count > 0 ? () => handleDrilldown(c.attribute) : undefined}
              >
                {c.count}
              </StyledCountCell>
            )}
          </td>
        ))}
      </StyledTopicsTableRow>

      <TopicsTableRowContainer
        aggregationDepth={depth + 1}
        topics={topic.topics}
        selectedTopicPath={selectedTopicPath}
        isImmediateParentExpanded={showChildTopics}
        isAncestorExpanded={isAncestorExpanded && showChildTopics}
        view={view}
        handleTopicPathSelection={handleTopicPathSelection}
      />
    </>
  );
};

type StyledProps = {
  depth: number;
  rowColor: string;
  hasNoChildren: boolean;
  hasParent: boolean;
  addDefaultCursor: boolean;
};

const StyledTopicsTableRow = styled.tr<StyledProps>`
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
  height: 59px;
  background-color: ${(props) => props.rowColor};
  cursor: default;
  font-size: 1.15em;

  &:hover:not(.selected) {
    background: ${Color.sky15};
    -webkit-transition: background-color 300ms ease-out;
    transition: background-color 300ms ease-out;
  }

  &.hidden {
    display: none;
  }

  &.visible {
    display: revert;
  }

  td {
    border-bottom: 0.5px solid ${Color.blue20};
    color: ${Color.gray50};

    :first-child {
      padding: ${({ hasParent }) => (hasParent ? "8px 0px 8px 12px" : "16px 0px 16px 12px")};

      :hover {
        cursor: ${({ addDefaultCursor }) => (!addDefaultCursor ? "pointer" : "default")};
      }
    }

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8) {
      padding: 16px 8px;
    }
  }
`;

const StyledCellNameContainer = styled.div<{
  depth: number;
  hasNoChildren: boolean;
  hasParent: boolean;
}>`
  display: flex;
  align-items: center;
  overflow: hidden;
  margin-left: ${(props) => 22 * (props.depth - 1) + "px"};
  padding-left: ${(props) => props.hasNoChildren && "24px"};
  max-height: ${(props) => props.hasParent && "46px"};
  min-height: ${(props) => props.hasNoChildren && "34px"};
  cursor: ${({ hasNoChildren }) => hasNoChildren && "default"};
`;

const StyledTopicNameContainer = styled.div`
  max-width: 240px;
  overflow: hidden;
  margin-left: 8px;
`;

const StyledTopicName = styled.div`
  text-overflow: ellipsis;
  word-break: normal;
  overflow: hidden;
  white-space: nowrap;
  max-width: 250px;
  font-size: 1.06em;
  font-weight: bold;
`;

const StyledTopicParentNameContainer = styled.div`
  margin-bottom: 5px;
  color: ${Color.gray30};
  font-size: 0.79em;
`;

const StyledTopicParentName = styled(StyledTopicName)`
  max-width: 175px;
  font-size: 1em;
`;

const StyledTopicParentNameTooltip = styled.div`
  svg {
    fill: ${Color.white};
    margin-top: 2px;
  }
`;

const StyledCountCell = styled.div<{ isGreaterThanZero: boolean; cursorStyle: string }>`
  background-color: ${({ isGreaterThanZero }) => (isGreaterThanZero ? Color.sky20 : "transparent")};
  padding: 6px 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
  cursor: ${({ cursorStyle }) => cursorStyle};
`;

const StyledSentimentCell = styled.div<{ cursorStyle: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background-color: ${Color.sky20};
  padding: 6px 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
  cursor: ${({ cursorStyle }) => cursorStyle};

  svg {
    margin-right: 8px;
  }
`;
