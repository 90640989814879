import React from "react";
import styled from "styled-components";

import { Skeleton } from "components/Skeleton";
import { Color } from "ts/enums/color";

export const CommentListPlaceholder = () => (
  <>
    {[...Array(8)].map((_, i) => (
      <StyledCommentPlaceholder className="pulse" key={i}>
        <div>
          <Skeleton width={800} height={16} responsive={true} />
          <Skeleton width={600} height={16} responsive={true} />
        </div>
        <StyledRightSquares>
          <Skeleton width={16} height={16} backgroundColor={Color.sky50} />
          <Skeleton width={16} height={16} backgroundColor={Color.sky50} />
        </StyledRightSquares>
      </StyledCommentPlaceholder>
    ))}
  </>
);

const StyledCommentPlaceholder = styled.div`
  box-sizing: content-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  border-bottom: 1px solid ${Color.sky20};
  padding: 10px 5px;
  div {
    border-radius: 4px;
  }
`;

const StyledRightSquares = styled.div`
  display: flex;
  align-items: center;
  > div {
    margin-left: 16px;
    border-radius: 4px;
  }
`;
