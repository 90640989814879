import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";

import { StatisticsType, CommentsView } from "@explorance/mly-types";
import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Color } from "ts/enums/color";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";
import { CustomToastContentType } from "ts/enums/customToastContentType";

import { useAppDispatch } from "store";
import { showToastError, showToastSuccess } from "store/toast/toastSlice";
import { AnalysisContext } from "context/AnalysisContext";
import { useQueryParams } from "hooks/useQueryParams";
import useClickOutside from "hooks/useClickOutside";

import { exportAnalysis, ExportAnalysisRequestBody } from "services/downloadedFiles";
import { getExportName } from "services/analysis";

import { isAlertRangeActive } from "utils/isAlertRangeActive";
import { statisticsURLStringToArray } from "utils/comments";
import { formatDemographicsForApi } from "utils/filters";

import { Button } from "components/_buttons/Button";
import { Icon, IconType } from "components/_icons/Icon";
import { StyledDropdownTransition } from "components/DropdownMenu/StyledDropdownTransition";
import { Text } from "components/Text";

export const ExportButton = () => {
  const dispatch = useAppDispatch();

  const [state] = useContext(AnalysisContext);
  const [preparingExport, setPreparingExport] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [fileName, setFileName] = useState("");

  const popupRef = useClickOutside(() => setIsOpen(false));

  const { view, search, custom_model_id, statistics, sort, sharingPreview } = useQueryParams();

  const isFileNameEmptyOrWhitespace = fileName.trim().length === 0;

  const getExportFileName = async () => {
    const response = await getExportName(state.analysisDetails.id);
    const filename = response.data.exportName;
    setFileName(filename);
  };

  const handleButtonClick = (event) => {
    event.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleExportComments = async () => {
    setPreparingExport(true);
    setIsOpen(false);

    const applyModelTopicsThresholdFilters =
      view === CommentsView.categorizedComments || view === CommentsView.categorizedRecommendations;
    const statisticsList = statisticsURLStringToArray(statistics).filter(
      (s) => s !== StatisticsType.total
    );

    const requestBody: ExportAnalysisRequestBody = {
      analysisId: state.analysisDetails.id,
      name: fileName,
      view: view,
      ...(applyModelTopicsThresholdFilters &&
        custom_model_id && { custom_model_id: Number(custom_model_id) }),
      search: search,
      filters: {
        ...(applyModelTopicsThresholdFilters && {
          topics: state.selectedTopicNodes.map((tn) => tn.fullPath),
        }),
        demographics: formatDemographicsForApi(state.selectedDemographicFilters),
        ...(!!state.threshold &&
          applyModelTopicsThresholdFilters && { threshold: state.threshold }),
        ...(state.selectedColumnFilters.length > 0 && {
          selectedColumns: state.selectedColumnFilters,
        }),
        ...(state.analysisDetails?.availableResults?.alerts === true &&
          isAlertRangeActive(state.selectedAlertRange) && {
            alertScore: state.selectedAlertRange,
          }),
        ...(statisticsList.length > 0 && { statistics: statisticsList }),
      },
      ...(sort && { sort }),
    };
    try {
      const { status } = await exportAnalysis(requestBody);
      setPreparingExport(false);

      if (status === 200 || status === 202) {
        getExportFileName();
        dispatch(
          showToastSuccess({
            message: {
              titleKey: "toast.downloadedFiles.title",
              customToastContentType: CustomToastContentType.exportedFile,
            },

            options: { noFade: true },
          })
        );
        return;
      }
      if (status === 204) {
        dispatch(
          showToastError({
            message: { titleKey: "toast.downloadedFiles.error" },
            options: { delay: 5000 },
          })
        );
        return;
      }
    } catch (e) {
      if (!axios.isCancel(e)) {
        setPreparingExport(false);
        dispatch(showToastError("toast.defaultError"));
      }
    }
  };

  const handleFileNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (inputValue.length <= 100) {
      setFileName(inputValue.trimStart());
    }
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => e.target.select();

  useEffect(() => {
    getExportFileName();
  }, [state.analysisDetails.name]); //eslint-disable-line

  return (
    <StyledExportContainer>
      <Button
        variant={ButtonVariant.secondary}
        size={ButtonSize.sm}
        onClick={handleButtonClick}
        loading={preparingExport}
        disabled={!!sharingPreview}
      >
        <Icon type={IconType.export} size={16} style={{ marginRight: 8 }} />
        <Text resource="button.exportResults" />
      </Button>
      <StyledExportMenu ref={popupRef} show={isOpen}>
        <StyledCardTitle>
          <Text resource="comments.exportName.label" />
        </StyledCardTitle>
        <StyledExportInput
          type="text"
          value={fileName}
          onChange={handleFileNameChange}
          onFocus={handleFocus}
        />
        <StyledButtonsContainer>
          <Button
            variant={ButtonVariant.light}
            onClick={() => setIsOpen(false)}
            size={ButtonSize.sm}
            style={{
              marginLeft: "auto",
            }}
          >
            <Text resource="button.cancel" />
          </Button>
          <Button
            variant={ButtonVariant.primary}
            onClick={handleExportComments}
            size={ButtonSize.sm}
            disabled={isFileNameEmptyOrWhitespace}
            style={{
              marginLeft: "12px",
            }}
          >
            <Text resource="button.export" />
          </Button>
        </StyledButtonsContainer>
      </StyledExportMenu>
    </StyledExportContainer>
  );
};

const StyledExportInput = styled.input`
  width: 100%;
  margin-top: 8px;
  border-radius: 2px;
  padding: 8px 10px 8px 10px;
  border: 1px solid ${Color.sky50};
`;
const StyledButtonsContainer = styled.div`
  display: flex;
  margin-top: 12px;
`;

const StyledExportContainer = styled.div`
  position: relative;
`;

const StyledCardTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  color: ${Color.gray50};
`;

const StyledExportMenu = styled(StyledDropdownTransition)<{ show: boolean }>`
  position: absolute;
  background-color: ${Color.white};
  right: 0;
  width: 374px;
  height: 124px;
  z-index: ${ZIndexStackingContext.high};
  padding: 12px;
  border-radius: 3px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
`;
